<template>
  <v-row class="d-flex flex-column">
    <v-col>
      <v-row>
        <v-breadcrumbs :items="breadcrumbs" class="px-4">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to"
              :disabled="item.disabled"
            >
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-row>

      <v-form
        ref="form"
        v-model="form.valid"
        lazy-validation>
        <v-row class="pt-1">
          <v-col cols="12">
            <v-alert
              v-model="ui.alert.enable"
              :dismissible="ui.alert.dismissible"
              :icon="ui.alert.icon"
              :type="ui.alert.type"
              class="mb-0 mt-3 mb-3"
            >
              {{ ui.alert.message }}
            </v-alert>
          </v-col>
          <v-col cols="12" sm="3" class="pt-0 d-xs-none">
            <v-card outlined
                    class="rounded-lg overflow-hidden">
              <v-row
                style="border-radius: 0"
                class="blue py-6 pb-9 px-5 px-sm-9">
                <v-col cols="12" class="pb-0">
                  <v-toolbar-title class="d-flex flex-column title white--text pl-0">
                    <v-icon class="my-10" size="125">mdi-barcode-scan</v-icon>
                  </v-toolbar-title>
                  <p class="white--text mb-2">
                    {{ form.default_data.label }}</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="9" class="pt-0">
            <v-card outlined
                    :loading="ui.loading"
                    :disabled="ui.disabled"
                    class="rounded-lg overflow-hidden">
              <template slot="progress">
                <v-progress-linear
                  color="blue-grey darken-3"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-row
                style="border-radius: 0"
                class="blue py-6 px-5 px-sm-9">
                <v-col cols="12" class="pb-0">
                  <v-toolbar-title class="title white--text pl-0">
                    {{ $vuetify.lang.t('$vuetify.product.products.edit.ui.title') }}
                  </v-toolbar-title>
                </v-col>
              </v-row>
              <v-card-text class="px-0 pb-0">
                <div class="px-5 px-sm-9 pt-6">
                  <v-row>
                    <v-col cols="12">
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-tag-text-outline</v-icon>
                        <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.title') }}
                      </span>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="form.data.reference"
                        :counter="45"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.reference.name')+'*'"
                        :hint="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.reference.hint')"
                        :rules="form.rules.reference"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="form.data.reference_provider"
                        :counter="45"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.reference_provider.name')"
                        :hint="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.reference_provider.hint')"
                        :rules="form.rules.reference_provider"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="form.data.reference_constructor"
                        :counter="45"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.reference_constructor.name')+'*'"
                        :hint="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.reference_constructor.hint')"
                        :rules="form.rules.reference_constructor"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="form.data.ean"
                        :counter="45"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.ean.name')"
                        :hint="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.ean.hint')"
                        :rules="form.rules.ean"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        :counter="75"
                        v-model="form.data.manufacturer"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.manufacturer')"
                        :rules="form.rules.manufacturer"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        :counter="25"
                        suffix="kg"
                        v-model="form.data.weight"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.weight')"
                        :rules="form.rules.weight"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <template>
                        <v-container
                          class="px-0 d-flex justify-space-around"
                          fluid
                        >
                          <v-checkbox
                            :disabled="!!form.data.is_bundle"
                            v-model="form.data.is_service"
                            :label="$vuetify.lang.t('$vuetify.product.products.edit.ui.is_service.title')"
                          ></v-checkbox>
                          <v-checkbox
                            :disabled="!(!!form.data.is_bundle)"
                            v-model="form.data.is_solution"
                            :label="$vuetify.lang.t('$vuetify.product.products.edit.ui.is_solution.title')"
                          ></v-checkbox>
                        </v-container>
                      </template>
                    </v-col>
                    <!-- Product main image component  -->
                    <v-col>
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-image-outline</v-icon>
                        <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.edit.ui.image.title') }}
                      </span>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-img
                        max-height="250"
                        max-width="250"
                        :src="form.default_data.image"
                      ></v-img>
                      <v-file-input
                        v-model="form.data.image"
                        :rules="form.rules.image"
                        accept="image/*"
                        :label="$vuetify.lang.t('$vuetify.product.products.edit.ui.image.label')"
                        :messages="$vuetify.lang.t('$vuetify.product.products.edit.ui.image.hint')"
                      ></v-file-input>
                    </v-col>
                    <!-- Product extra images component  -->
                    <v-col cols="12">
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-image-multiple-outline</v-icon>
                        <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.edit.ui.images.title') }}
                      </span>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="3" class="d-flex child-flex" v-for="(i, index) in form.extra_images" :key="i.id">
                      <v-img style="border:1px solid black"
                        aspect-ratio="1"
                        contain
                        :src="i.path"
                        class="grey lighten-2">
                        <v-app-bar
                          flat
                          color="rgba(0, 0, 0, 0)">
                          <v-spacer></v-spacer>
                          <v-btn
                            color="black"
                            icon>
                            <v-icon @click="removeImages(index, i.id)">mdi-close</v-icon>
                          </v-btn>
                        </v-app-bar>
                      </v-img>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-file-input
                        v-model="form.data.images"
                        :rules="form.rules.images"
                        :value="form.data.images"
                        :messages="$vuetify.lang.t('$vuetify.product.products.edit.ui.images.hint')"
                        multiple
                        accept="image/*"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.images.name')"
                      ></v-file-input>
                    </v-col>
                    <!-- Add product description component  -->
                    <v-col>
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-note-text-outline</v-icon>
                        <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.product.title') }}
                      </span>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :counter="200"
                        v-model="form.data.label"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.product.inputs.label.name')+'*'"
                        :hint="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.product.inputs.label.hint')"
                        :rules="form.rules.label"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-textarea
                        v-model="form.data.description_short"
                        outlined
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.product.inputs.description_short.name')"
                        :hint="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.product.inputs.description_short.hint')"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <p class="mb-1"> {{
                          $vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.product.inputs.description.name')
                                       }}</p>
                      <quillEditor ref="quillEditor" v-model="form.data.description" :options="editorOptionDescription"
                      ></quillEditor>
                    </v-col>
                    <!-- Add product characteristic component  -->
                    <v-row>
                      <v-col cols="12" class="mt-8">
                        <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                          <v-icon class="pl-3 pr-3" color="purple">mdi-wrench-outline</v-icon>
                          <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.characteristics.title') }}
                      </span>
                        </div>
                        <v-divider></v-divider>
                        <wysiwyg v-model="form.data.characteristics" :options="editorOptionCharacteristics" placeholder="Terrain de jeu pour les caractéristiques" />
                      </v-col>
                    </v-row>
                    <!-- End product characteristic component  -->
                    <!-- Add product financial information component  -->
                    <v-col cols="12" class="mt-8">
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2 ">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-piggy-bank-outline</v-icon>
                        <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.title') }}
                      </span>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <!-- Add product bundle component  -->
                    <v-col cols="12">
                      <v-switch
                        v-model="form.data.is_bundle"
                        :label="$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.switch')"
                        color="indigo darken-3"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="12" v-if="form.data.is_bundle">
                      <v-autocomplete
                        v-model="form.data.bundle_items"
                        :item-text="item => item.reference +' - '+ item.label"
                        return-object
                        :items="products"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.bundle.select')"
                        :rules="form.rules.bundle"
                        clearable
                        dense
                        deletable-chips
                        small-chips
                        hide-no-data
                        multiple
                        hide-selected
                        @input="recalculateTotal"
                      >
                      </v-autocomplete>
                      <div v-if="!_.isEmpty(form.data.bundle_items)" class="mb-6">
                        <p class="font-weight-medium mt-6">
                          {{
                            $vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.bundle.title')
                          }}
                        </p>
                        <v-data-table :headers="bundleDatable.headers" :items="form.data.bundle_items"
                                      :hide-default-footer="true" item-key="id"
                                      dense>
                          <template v-slot:item.reference="{ item }">
                            <span class="font-weight-bold">
                              {{ item.reference }}
                            </span>
                          </template>
                          <template v-slot:item.order="{ item }">
                            <v-text-field v-model="item.order" :rules="form.rules.order" @input="validateProductsChilds" :ref="'productChild'+item.id">
                              {{ _.isUndefined(item.order) ? item.order = form.data.bundle_items.findIndex(function (itemIterated) { return itemIterated.id === item.id}) + 1 : item.order }}
                            </v-text-field>
                          </template>
                          <template v-slot:item.quantity="{ item }">
                            <v-text-field v-model="item.qt" :rules="form.rules.bundle_quantity"
                                          @input="recalculateTotal">
                              {{ _.isUndefined(item.qt) ? 1 : item.qt }}
                            </v-text-field>
                          </template>
                          <template v-slot:item.price_taxless="{ item }">
                            <span class="font-weight-bold">
                              {{ helpers.toEuro(item.price_taxless) }}
                            </span>
                          </template>
                        </v-data-table>
                      </div>
                    </v-col>
                    <!-- End product bundle component  -->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.data.price_taxless"
                        append-icon="mdi-currency-eur"
                        :rules="form.rules.price_taxless"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.inputs.price_taxless.name')+'*'"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.data.price_starting"
                        append-icon="mdi-currency-eur"
                        :rules="form.rules.price_taxless"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.inputs.price_starting.name')+'*'"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="form.data.rent_taxless"
                        append-icon="mdi-currency-eur"
                        :rules="form.rules.rent_taxless"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.inputs.rent_taxless.name')+'*'"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="form.data.rent_months"
                        :rules="form.rules.rent_taxless"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.inputs.rent_months.name')"
                        :hint="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.inputs.rent_months.name')"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-checkbox
                        v-model="form.data.show_monthly_price"
                        :label="$vuetify.lang.t('$vuetify.product.products.edit.ui.intro.financial.show_monthly_price.name')"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        v-model="form.data.vat"
                        :item-text="item => item.code +' - '+ item.value + '%'"
                        item-value="id"
                        :rules="form.rules.vat"
                        :items="vats"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.inputs.vat.name')"
                        clearable
                        dense
                        hide-selected
                        hide-no-data
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <div style="width:100%" v-if="!_.isEmpty(form.data.taxes)">
                        <p class="font-weight-bold">
                          {{
                            $vuetify.lang.t('$vuetify.product.products.edit.ui.intro.financial.taxes.existing')
                          }}
                        </p>
                        <v-col cols="12" class="d-flex pt-0 pb-0" style="align-items:baseline"
                               v-for="(tax, index) in form.data.taxes" :key="tax.id">
                          <v-col cols="4">
                            <v-autocomplete
                              v-model="tax.tax_id"
                              :label="$vuetify.lang.t('$vuetify.product.products.edit.ui.intro.financial.taxes.form.name')"
                              clearable
                              dense
                              :items="taxes"
                              item-text="name"
                              item-value="id"
                              hide-no-data
                              hide-selected
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field v-model="tax.value"
                                          :counter="45"
                                          :rules="form.rules.tax_value"
                                          :label="$vuetify.lang.t('$vuetify.product.products.edit.ui.intro.financial.taxes.form.value')"
                                          required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2">
                            <v-autocomplete
                              v-model="tax.tax_type"
                              :items="types"
                              item-text="name"
                              item-value="value"
                              :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.financial.inputs.taxes.type')"
                              required
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="2" class="text-center">
                            <v-btn
                              color="error"
                              fab
                              x-small
                              dark
                              @click="removeTaxes(index)"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </v-col>
                        </v-col>
                      </div>
                      <p class="font-weight-bold mt-6">
                        {{
                          $vuetify.lang.t('$vuetify.product.products.edit.ui.intro.financial.taxes.new')
                        }}
                      </p>
                      <SelectInput ref="selectInput" v-for="(row, index) in tax_component"
                                   :items="taxes"
                                   :key="row.id"
                                   :rules_amount="form.rules.tax_value"
                                   item_text="name" item_value="id" :index="index"
                                   v-on:tax-line-remove="removeTax(index)"
                                   @add="addSelectInputComponent"></SelectInput>
                    </v-col>
                  </v-row>
                  <!-- Add product category component  -->
                  <v-row>
                    <v-col cols="12" class="mt-8">
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-shape-outline</v-icon>
                        <span class="black--text text-center mx-auto">
                       {{ $vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.families.name') }}
                         </span>
                        <div class="text-right float-right">
                          <v-dialog
                            v-model="add_dialog_family"
                            persistent
                            max-width="600"
                          >
                            <dialog-add-family :on-confirm="()=>{fetchFamilies()}"
                                               :on-cancel="()=>{add_dialog_family = false}"></dialog-add-family>
                          </v-dialog>
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="success"
                                fab
                                x-small
                                dark
                                @click="add_dialog_family = true"
                              >
                                <v-icon class="text-right">mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            {{ $vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.families.button') }}
                          </v-tooltip>
                        </div>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" sm="12" class="pb-6">
                      <v-autocomplete
                        v-model="form.data.selected_families"
                        item-text="_path"
                        item-value="id"
                        return-object
                        :items="families"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.families.label')"
                        clearable
                        dense
                        multiple
                        deletable-chips
                        small-chips
                        hide-no-data
                        hide-selected
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- Add product similar component  -->
                  <v-row>
                    <v-col cols="12" class="mt-8">
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-google-circles-extended</v-icon>
                        <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.similar.name') }}
                      </span>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" sm="12" class="pb-6">
                      <v-autocomplete
                        v-model="form.data.selected_similar"
                        :item-text="item => item.reference +' - '+ item.label"
                        return-object
                        item-value="id"
                        :items="products_similar"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.similar.label')"
                        clearable
                        dense
                        multiple
                        deletable-chips
                        small-chips
                        hide-no-data
                        hide-selected
                        :menu-props="form.autocomplete_menu_props_similar"
                        @input="updateAutocomplete(form.data.selected_similar, 'similar')"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- Add product extra component  -->
                  <v-row>
                    <v-col cols="12" class="mt-8">
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-google-circles-communities</v-icon>
                        <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.extra.name') }}
                      </span>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" sm="12" class="pb-6">
                      <v-autocomplete
                        v-model="form.data.selected_extra"
                        return-object
                        :item-text="item => item.reference +' - '+ item.label"
                        item-value="id"
                        :items="products_extra"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.extra.label')"
                        clearable
                        dense
                        multiple
                        deletable-chips
                        small-chips
                        hide-no-data
                        hide-selected
                        :menu-props="form.autocomplete_menu_props_extra"
                        @input="updateAutocomplete(form.data.selected_extra, 'extra')"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- Add product file component  -->
                  <v-row>
                    <v-col cols="12" class="mt-8">
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-paperclip</v-icon>
                        <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.files.files') }}
                      </span>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <div style="width: 100%" v-if="!_.isEmpty(form.data.files)">
                      <p class="font-weight-bold mt-6">
                        {{
                          $vuetify.lang.t('$vuetify.product.products.edit.ui.files.existing')
                        }}
                      </p>
                      <v-col cols="12" class="d-flex" style="align-items:baseline"
                             v-for="(file, index) in form.data.files" :key="file.id">
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="file.file_type_id"
                            :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.files.file_type')"
                            clearable
                            dense
                            :items="file_types"
                            item-text="label"
                            item-value="id"
                            hide-no-data
                            hide-selected
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                          <p class="font-weight-medium mt-6">
                            {{
                              file.name
                            }}
                          </p>
                        </v-col>
                        <v-col cols="2" class="text-center">
                          <v-btn
                            color="error"
                            fab
                            x-small
                            dark
                            @click="removeFiles(index, file.id)"
                          >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-col>
                    </div>
                    <p class="font-weight-bold mt-6">
                      {{
                        $vuetify.lang.t('$vuetify.product.products.edit.ui.files.new')
                      }}
                    </p>
                    <FileUpload ref="fileUpload" v-for="(row, index) in files_component" :items="file_types"
                                :key="row.id" :rules="form.rules.files"
                                item_text="label" item_value="id" :index="index"
                                @remove="removeFileUploadComponent(index)"
                                @add="addFileInputComponent"></FileUpload>
                    <!-- End product file component  -->
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <p class="mt-6 pb-0 mb-0 text-caption">
                        {{ $vuetify.lang.t('$vuetify.global.form.required_fields') }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-card-actions class="d-xs-block px-3 px-sm-7">
                <v-btn
                  text
                  v-on:click="reset()">
                  {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  v-on:click="submit()">
                  {{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-fab-transition>
      <div class="v-btn-right-bottom">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue-grey lighten-1"
              fab
              dark
              small
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              :to="{name:'GeneralProductsList'}">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.global.back') }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue-grey lighten-1"
              small
              fab
              dark
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              :to="{name:'GeneralProductsOne'}">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.global.details') }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green lighten-1"
              small
              fab
              dark
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              @click="submit">
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}</span>
        </v-tooltip>
      </div>
    </v-fab-transition>
  </v-row>
</template>
<script>
import _ from 'lodash'

import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import DialogAddFamily from '../families/dialog/Add'
import SelectInput from '@/components/SelectInput'
import FileUpload from '@/components/FileUpload'
import helpers from '@/plugins/helpers'

export default {
  components: {
    quillEditor,
    SelectInput,
    'dialog-add-family': DialogAddFamily,
    FileUpload
  },
  data: () => ({
    types: [
      {
        value: '%',
        name: '%'
      }, {
        value: '€',
        name: '€'
      }
    ],
    tax_component: [], // array to hold all SelectInput components for taxes
    files_component: [], // array to hold all FileUploadComponents
    add_dialog_family: false, // dialog to add a product family
    tax_id: 0, // key for SelectInput component
    files_id: 0, // key for FileUploadComponent
    editorOptionDescription: {
      placeholder: 'Terrain de jeu pour la description longue du produit (image, vidéo, etc)'
    },
    editorOptionCharacteristics: {
      maxHeight: '300px'
    },
    is_bundle: false,
    ui: {
      loading: false,
      disabled: false,
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    breadcrumbs: [],
    bundleDatable: {
      headers: [],
      items: []
    },
    form: {
      valid: false,
      default_data: {},
      data: {
        reference: '',
        reference_provider: '',
        reference_constructor: '',
        manufacturer: '',
        ean: '',
        weight: 0,
        label: '',
        description_short: '',
        description: '',
        characteristics: '',
        price_taxless: '',
        price_starting: '',
        rent_taxless: '',
        rent_months: '',
        vat: '',
        image: null,
        images: [],
        is_bundle: '',
        is_service: false,
        is_solution: false,
        bundle_items: [],
        taxes: [], // new to add taxes
        selected_taxes: [], //  existing taxes
        selected_similar: [],
        selected_extra: [],
        selected_families: [],
        files: [], // new files to be uploaded
        selected_files: [] // existing files
      },
      extra_images: [],
      extra_images_to_delete: [],
      files_to_delete: [],
      rules: {},
      max_counter_selected_products: 3, // max for similar and extra products
      autocomplete_menu_props_similar: { // disable similar autocomplete
        disabled: false
      },
      autocomplete_menu_props_extra: { // disable extra autocomplete
        disabled: false
      }
    },
    families: [], // fetched families
    products: [], // fetched products
    products_similar: [], // filter products and populate similar array depending on the ones selected in extra
    products_extra: [], // filter products and populate extra array depending on the ones selected in similar
    taxes: [], // fetched all taxes
    vats: [], // fetched all vats
    file_types: [] // fetched file types
  }),
  computed: {},
  methods: {
    /**
     * Removes extra images from the view and add the ids to be removed to array
     * */
    removeImages (index, imageId) {
      this.form.extra_images_to_delete.push(imageId)
      this.form.extra_images.splice(index, 1)
    },
    /**
     * Removes existing files from the view and add the ids to be removed to array
     * */
    removeFiles (index, fileId) {
      this.form.files_to_delete.push(fileId)
      this.form.data.files.splice(index, 1)
    },
    /**
     * Removes existing taxes from the view
     * */
    removeTaxes (index) {
      this.form.data.taxes.splice(index, 1)
    },
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.form.extra_images_to_delete = []
      this.form.files_to_delete = []

      setTimeout(() => {
        // general information
        this.form.data.reference = this.form.default_data.reference
        this.form.data.reference_provider = this.form.default_data.reference_provider
        this.form.data.reference_constructor = this.form.default_data.reference_constructor
        this.form.data.manufacturer = this.form.default_data.manufacturer
        this.form.data.ean = this.form.default_data.ean
        this.form.data.weight = this.form.default_data.weight
        this.form.data.label = this.form.default_data.label
        this.form.data.is_service = this.form.default_data.is_service
        this.form.data.is_solution = this.form.default_data.is_solution
        // description
        this.form.data.description_short = this.form.default_data.description_short
        this.form.data.description = this.form.default_data.description
        this.form.data.characteristics = this.form.default_data.characteristics
        // bundle
        this.form.data.is_bundle = this.form.default_data.is_bundle
        this.form.data.bundle_items = !_.isUndefined(this.form.default_data.bundle) ? JSON.parse(JSON.stringify(this.form.default_data.bundle)) : []
        // financial
        this.form.data.price_taxless = this.form.default_data.price_taxless
        this.form.data.price_starting = this.form.default_data.price_starting
        this.form.data.rent_taxless = this.form.default_data.rent_taxless
        this.form.data.rent_months = this.form.default_data.rent_months
        this.form.data.show_monthly_price = this.form.default_data.show_monthly_price
        this.form.data.vat = this.form.default_data.vat_id
        this.form.data.taxes = this.form.default_data.taxes
        // attachements
        this.form.data.selected_similar = this.form.default_data.similar
        this.form.data.selected_extra = this.form.default_data.extra
        this.form.data.selected_families = this.form.default_data.families
        // images and files
        this.form.extra_images = this.form.default_data.images
        this.form.data.files = this.form.default_data.files
        this.form.data.selected_files = this.form.default_data.selected_files
      }, 10)
    },
    validate () {
      return this.$refs.form.validate()
    },
    validateProductChild (id) {
      return this.$refs['productChild' + id].validate()
    },
    validateProductsChilds () {
      for (const item in this.form.data.bundle_items) {
        this.validateProductChild(this.form.data.bundle_items[item].id)
      }
    },
    goTop () {
      this.$vuetify.goTo(0)
    },
    /**
     * Fetches product families and populates dropdown
     * */
    async fetchFamilies () {
      try {
        this.families = (await this.axios.get('/general/family/all', {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
      } catch (e) {
        this.Sentry.captureException(e)
        this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.product.families.list.notification.load'))
      }
    },
    /**
     * Fetches taxes and populates dropdown
     * */
    async fetchTaxes () {
      try {
        this.taxes = (await this.axios.get('/general/tax/all', {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
      } catch (e) {
        this.Sentry.captureException(e)
        this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.product.families.list.notification.load'))
      }
    },
    /**
     * Fetches file types and populates dropdown
     * */
    async fetchFileTypes () {
      try {
        this.file_types = (await this.axios.get('/general/filetype/all', {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
      } catch (e) {
        this.Sentry.captureException(e)
        this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.product.families.list.notification.load'))
      }
    },
    /**
     * Pushes a selectInput into the array (tax name + value + type)
     * */
    addSelectInputComponent () {
      this.tax_component.push({
        id: this.tax_id++,
        value: SelectInput
      })
    },
    /**
     * Removes a selectInput
     * */
    removeTax (id) {
      if (this.tax_component.length > 1) {
        this.tax_component.splice(id, 1)
      } else {
        this.tax_component.splice(id, 1)
        this.addSelectInputComponent()
      }
    },
    /**
     * Pushes a fileUpload into the array (file type + file)
     * */
    addFileInputComponent () {
      this.files_component.push({
        id: this.files_id++,
        value: FileUpload
      })
    },
    /**
     * Removes a fileUpload
     * */
    removeFileUploadComponent (id) {
      if (this.files_component.length > 1) {
        this.files_component.splice(id, 1)
      } else {
        this.files_component.splice(id, 1)
        this.addFileInputComponent()
      }
    },
    /**
     * Fetches products and populates dropdown for product extra and product similar
     * */
    async fetchProducts () {
      try {
        this.products = (await this.axios.get('/general/products/all', {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
        this.products_similar = this.products
        this.products_extra = this.products
      } catch (e) {
        this.Sentry.captureException(e)
        this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.product.families.list.notification.load'))
      }
    },
    /**
     * Fetches VATs and populates dropdown
     * */
    async fetchVats () {
      try {
        this.vats = (await this.axios.get('/administration/vat/all', {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
      } catch (e) {
        this.Sentry.captureException(e)
        this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.product.families.list.notification.load'))
      }
    },
    async fetchProduct (id) {
      try {
        this.ui.loading = true
        this.ui.disabled = true

        this.form.default_data = (await this.axios.get('/general/products/one/' + id, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
        this.reset()
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 404:
              await this.$router.push({
                name: 'GeneralProductsList',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.product.products.edit.notification.not_found')
                  }
                }
              })
              break

            default:
              await this.$router.push({
                name: 'GeneralProductsList',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert-octagon',
                    type: 'error',
                    message: this.$vuetify.lang.t('$vuetify.product.products.edit.notification.unknown_error')
                  }
                }
              })
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    },
    async submit () {
      if (!this.validate()) {
        return false
      }

      try {
        this.ui.loading = true
        this.ui.disabled = true

        // format taxes
        this.cleanTaxes()
        // format file upload
        this.cleanFileUpload()
        const bodyFormData = new FormData()
        bodyFormData.append('reference', this.form.data.reference)
        bodyFormData.append('reference_provider', this.form.data.reference_provider)
        bodyFormData.append('reference_constructor', this.form.data.reference_constructor)
        bodyFormData.append('ean', this.form.data.ean)
        bodyFormData.append('is_bundle', this.form.data.is_bundle)
        bodyFormData.append('is_solution', this.form.data.is_solution === null ? false : this.form.data.is_solution)
        bodyFormData.append('is_service', this.form.data.is_service === null ? false : this.form.data.is_service)
        bodyFormData.append('label', this.form.data.label)
        bodyFormData.append('weight', this.form.data.weight === null ? -1 : this.form.data.weight)
        bodyFormData.append('description', this.form.data.description)
        bodyFormData.append('description_short', this.form.data.description_short)
        bodyFormData.append('characteristics', this.form.data.characteristics)
        bodyFormData.append('manufacturer', this.form.data.manufacturer)
        bodyFormData.append('price_taxless', this.form.data.price_taxless)
        bodyFormData.append('price_starting', this.form.data.price_starting)
        bodyFormData.append('rent_taxless', this.form.data.rent_taxless)
        bodyFormData.append('rent_months', this.form.data.rent_months)
        bodyFormData.append('show_monthly_price', this.form.data.show_monthly_price)
        bodyFormData.append('vat_id', this.form.data.vat)
        bodyFormData.append('image', this.form.data.image)
        // add new extra images
        _.forEach(this.form.data.images, function (key, value) {
          bodyFormData.append('images[' + value + ']', key, key.name.replace(/\s+/g, ''))
        })
        // delete extra images
        _.forEach(this.form.extra_images_to_delete, function (key, value) {
          bodyFormData.append('images_to_remove[' + value + ']', key)
        })
        // for already existing taxes
        _.forEach(this.form.data.taxes, function (key, value) {
          bodyFormData.append('taxes[' + key.tax_id + ']', key.value + ',' + key.tax_type)
        })
        // for new to add taxes
        _.forEach(this.form.data.selected_taxes, function (key, value) {
          bodyFormData.append('taxes[' + key.id + ']', key.value + ',' + key.tax_type)
        })
        _.forEach(this.form.data.selected_families, function (key, value) {
          bodyFormData.append('families[' + value + ']', key.id)
        })
        _.forEach(this.form.data.selected_similar, function (key, value) {
          bodyFormData.append('product_similar[' + value + ']', key.id)
        })
        _.forEach(this.form.data.selected_extra, function (key, value) {
          bodyFormData.append('product_extra[' + value + ']', key.id)
        })
        // if it's bundle, attach bundle items with quantities
        if (!_.isEmpty(this.form.data.bundle_items)) {
          _.forEach(this.form.data.bundle_items, function (key, value) {
            bodyFormData.append('bundle_items[' + key.id + ']', JSON.stringify({ quantity: key.qt, order: key.order }))
          })
        }
        // upload new files
        _.forEach(this.form.data.selected_files, function (key, value) {
          bodyFormData.append('files[' + key.id + ']', key.file)
        })
        // change file type
        _.forEach(this.form.data.files, function (key, value) {
          bodyFormData.append('change_file_type[' + key.id + ']', key.file_type_id)
        })
        // delete files
        _.forEach(this.form.files_to_delete, function (key, value) {
          bodyFormData.append('files_to_remove[' + value + ']', key)
        })
        // submit form

        await this.axios.post('/general/products/edit/' + this.$router.currentRoute.params.id, bodyFormData, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })
        // remove old alerts
        this.ui.alert.enable = false
        // get new modified product
        await this.fetchProduct(this.$router.currentRoute.params.id)
        this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.product.products.edit.notification.edited'))
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 302:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.product.products.edit.notification.exist'))
              break
            case 400:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.product.products.edit.notification.fail'))
              break
            case 406:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.product.products.edit.notification.product_family_not_found'))
              break
            case 410:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.product.products.edit.notification.extra_product_not_found'))
              break
            case 417:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.product.products.edit.notification.file'))
              break
            case 501:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.product.products.edit.notification.similar_product_not_found'))
              break
            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.product.products.edit.notification.unknown_save'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
        this.goTop()
      }
    },
    /**
     * Calculates total if product is bundle
     * */
    recalculateTotal () {
      this.form.data.price_taxless = this.form.data.bundle_items.reduce(function (prev, cur) {
        return prev + (_.isUndefined(cur.qt) ? 1 : cur.qt) * cur.price_taxless
      }, 0).toFixed(2)
    },
    /**
     *  Updates autocomplete input depending on products selected in the other autocomplete
     *  Products in similar cannot be in extra and vice versa
     *
     * @param selected Array of selected items
     * @param type String Type of autocomplete, can be 'extra' or 'similar'
     */
    updateAutocomplete (selected, type) {
      switch (type) {
        case ('similar'):
          this.form.autocomplete_menu_props_similar.disabled = selected.length >= this.form.max_counter_selected_products
          this.products_extra = this.products.filter(function (item) {
            return !_.includes(selected, item.id)
          })
          break
        case ('extra'):
          this.form.autocomplete_menu_props_extra.disabled = selected.length >= this.form.max_counter_selected_products
          this.products_similar = this.products.filter(function (item) {
            return !_.includes(selected, item.id)
          })
          break
        default:
          break
      }
    },
    cleanTaxes () {
      const self = this
      this.form.data.selected_taxes = []
      _.forEach(this.$refs.selectInput, function (ref) {
        if (!_.isNull(ref.selected_tax && !_.isNull(ref.value_tax) && !_.isNull(ref.type_tax))) {
          self.form.data.selected_taxes.push({
            id: ref.selected_tax,
            value: ref.value_tax,
            tax_type: ref.type_tax
          })
        }
      })
    },
    cleanFileUpload () {
      this.form.data.selected_files = []
      const self = this
      _.forEach(this.$refs.fileUpload, function (ref) {
        if (!_.isNull(ref.selected_file_type) && !_.isNull(ref.file)) {
          self.form.data.selected_files.push({
            id: ref.selected_file_type,
            file: ref.file
          })
        }
      })
    },
    getOrders () {
      return this._.map(this.form.data.bundle_items, (item) => { return parseInt(item.order) })
    }
  },
  watch: {
    'form.data.manufacturer': function (newVal, oldVal) {
      this.form.data.manufacturer = _.upperFirst(newVal)
    },
    'form.data.is_service': function (newVal, oldVal) {
      if (newVal === true) { this.form.data.is_solution = false }
    },
    'form.data.is_solution': function (newVal, oldVal) {
      if (newVal === true) { this.form.data.is_service = false }
    },
    'form.data.is_bundle': function (newVal, oldVal) {
      if (newVal === false) {
        this.form.data.is_solution = false
        this.form.data.is_service = this.form.default_data.is_service
      }
      if (newVal === true) {
        this.form.data.is_solution = this.form.default_data.is_solution
        this.form.data.is_service = false
      }
    }
  },
  created () {
    this.breadcrumbs = [
      {
        text: this.$vuetify.lang.t('$vuetify.home.name'),
        disabled: false,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.general.name'),
        disabled: true,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.product.name'),
        disabled: false,
        to: { name: 'GeneralProductsList' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.product.products.edit.name'),
        disabled: true,
        to: { name: 'GeneralProductsEdit' }
      }
    ]
    this.form.rules = {
      reference: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => (value && value.length <= 45) || this.$vuetify.lang.t('$vuetify.global.max_char')
      ],
      reference_provider: [
        value => {
          const error = this.$vuetify.lang.t('$vuetify.global.max_char')
          if (_.size(value) <= 0) return true
          if (value.length > 45) return error
          else return true
        }
      ],
      reference_constructor: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => (value && value.length <= 45) || this.$vuetify.lang.t('$vuetify.global.max_char')
      ],
      ean: [
        value => {
          const error = this.$vuetify.lang.t('$vuetify.global.max_char')
          if (_.size(value) <= 0) return true
          if (value.length > 45) return error
          else return true
        }
      ],
      manufacturer: [
        value => {
          const error = this.$vuetify.lang.t('$vuetify.global.max_char')
          if (_.size(value) <= 0) return true
          if (value.length > 75) return error
          else return true
        }],
      weight: [
        value => !isNaN(value) || this.$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.rules.must_be_number')
      ],
      image: [
        value => !value || value.size < 2000000 || this.$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.image.rules.size')
      ],
      files: [
        value => !value || value.size < 2000000 || this.$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.files.rules.size')
      ],
      images: [
        value => !value || !value.some(v => v.size > 2000000) || this.$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.images.rules.size')
      ],
      label: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => (value && value.length <= 200) || this.$vuetify.lang.t('$vuetify.global.max_char')
      ],
      price_taxless: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => !isNaN(value) || this.$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.rules.must_be_number'),
        value => (value && value >= 0) || this.$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.rules.not_zero')
      ],
      tax_value: [
        value => !isNaN(value) || this.$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.rules.must_be_number'),
        value => {
          const error = this.$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.rules.not_zero')
          if (value && value < 0) return error
          else return true
        }
      ],
      rent_taxless: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => !isNaN(value) || this.$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.rules.must_be_number'),
        value => (value && value >= 0) || this.$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.rules.not_zero')
      ],
      vat: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required')
      ],
      bundle: [
        value => (value && value.length > 0) || this.$vuetify.lang.t('$vuetify.global.required')
      ],
      bundle_quantity: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => !isNaN(value) || this.$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.rules.must_be_number'),
        value => (value && value > 0) || this.$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.rules.not_zero')
      ],
      order: [
        value => this.form.data.is_solution === true ? ((!!value) || this.$vuetify.lang.t('$vuetify.global.required')) : true,
        value => (!isNaN(value)) || this.$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.general.inputs.rules.must_be_number'),
        value => this.form.data.is_solution === true ? ((value > 0) || this.$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.rules.not_zero')) : true,
        value => this.form.data.is_solution === false ? ((isNaN(value) || value === '' || value > 0) || this.$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.rules.not_zero')) : true,
        value => helpers.uniqueInArray(parseInt(value), this.getOrders()) || this.$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.rules.must_be_unique')
      ]
    }
    this.bundleDatable.headers = [
      {
        value: 'reference',
        text: this.$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.datatable.reference')
      },
      {
        value: 'label',
        text: this.$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.datatable.label')
      },
      {
        value: 'order',
        text: this.$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.datatable.order')
      },
      {
        value: 'quantity',
        text: this.$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.datatable.quantity')
      },
      {
        value: 'price_taxless',
        align: 'right',
        text: this.$vuetify.lang.t('$vuetify.product.products.edit.ui.bundle.datatable.price_taxless')
      }
    ]
  },
  mounted () {
    this.fetchProduct(this.$router.currentRoute.params.id)
    this.$router.currentRoute.params.type === 'simple' ? this.is_bundle = false : this.is_bundle = true
    this.addSelectInputComponent()
    this.addFileInputComponent()
    this.fetchFamilies()
    this.fetchProducts()
    this.fetchTaxes()
    this.fetchVats()
    this.fetchFileTypes()
  }
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

::v-deep {
  .datatable {
    tr {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        th:first-child, td:first-child {
          padding-left: 36px;
        }

        th:last-child, td:last-child {
          padding-right: 36px;
        }
      }
    }
  }
}

::v-deep {
  .ql-container {
    height: 400px;
    max-height: 400px;
    min-height: 100%;
    overflow-y: auto;
  }

  .ql-editor {
    height: auto;
  }
}
</style>
